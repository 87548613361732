import {IMenuItem} from '../components/layout/header/types';

export const emailOptions = [
  {label: 'General enquiries', value: 'admin@hexact.io'},
  {label: 'Questions or troubleshooting', value: 'support@hexospark.com'},
  {
    label: 'All marketing enquiries and collaborations',
    value: 'chris.closset@hexact.io',
  },
  {label: 'Press', value: 'pr@hexact.io'},
];

export const cardsFirstNumbers: any = {
  VISA: '4242',
  MASTERCARD: '5555',
  'AMERICAN EXPRESS': '3782',
  DISCOVER: '6011',
  DINERSCLUB: '3056',
  JCB: '3566',
  UNIONPAY: '6200',
  'DINERS CLUB': '3056',
};

export const productList = [
  {count: 1, color: 'yellow'},
  {count: 5, color: 'green', popular: true},
  {count: 10, color: 'blue', stack: true},
];
export const productInfo = [
  'Number of email sender accounts to connect',
  'Workspaces',
  'Team access',
  'Email sending',
  'CRM contacts',
  'Unified inbox',
  'Additional file storage space',
  'Built-in CRM to manage leads from prospect to client',
  '1 to 1 personalization and bulk email campaigns',
  'A powerful browser extension to capture leads from LinkedIn and craft icebreakers in seconds',
  'Direct integration with Hexomatic',
  'Email tracking',
  'Email template library',
  'Image personalization',
  // 'Email warming (short-term roadmap)',
  'Built-in meeting booking (long-term roadmap)',
  'Advanced CRM (long-term roadmap)',
];

export const productsDetailsInfo = {
  Solo: [
    {plus: ''},
    {text: 'Email inbox', value: '1'},
    {text: 'Account storage', value: '1GB'},
    {text: 'Unlimited contacts', checked: true},
    {text: 'Unlimited emails', checked: true},
    {text: 'Built-in CRM', checked: true},
    {text: 'Unified inbox', checked: true},
    {text: 'Image personalization', checked: true},
    {
      text: 'Capture leads from Linkedin',
      text_row: '(requires browser extension)',
      checked: true,
    },
  ],
  Team: [
    {plus: 'All the benefits of Solo, plus'},
    //{text: 'Team access & workspaces', checked: true},
    {text: 'Email inboxes', select: true},
    {text: 'Account storage', storage: true},
    {text: 'Team seats', team: true},
    {text: 'Workspaces', workspaces: true},
  ],

  Enterprise: [
    {plus: 'All the benefits of Team, plus:'},
    {text: 'Team training', checked: true},
    {text: 'Personalized onboarding', checked: true},
    {text: 'Campaign setup and management', checked: true},
    {text: 'Dedicated strategy consultant', checked: true},
    {text: 'Custom plan', checked: true},
  ],
};

export const stuckOptions = [
  {label: 10, value: 10, count: 1, storage: 10, teammates: 8, workspaces: 3},
  {label: 30, value: 30, count: 2, storage: 30, teammates: 24, workspaces: 7},
  {label: 45, value: 45, count: 3, storage: 45, teammates: 36, workspaces: 10},
  {label: 60, value: 60, count: 4, storage: 60, teammates: 48, workspaces: 13},
  {label: 75, value: 75, count: 5, storage: 75, teammates: 60, workspaces: 16},
];

export const stuckOptionsLTD = [
  {label: 5, value: 5, count: 0.5, storage: 5, teammates: 4, workspaces: 2},
  {label: 10, value: 10, count: 1, storage: 10, teammates: 8, workspaces: 3},
  {label: 20, value: 20, count: 1.5, storage: 20, teammates: 16, workspaces: 5},
];

export const options = [1, 5, 10];

export const menuItemsList: IMenuItem[] = [
  {
    name: 'Why Hexospark',
    linkTo: '/why-hexospark',
    className: 'nav-link',
  },
  {name: 'Pricing', linkTo: '/pricing', className: 'nav-link'},
  {
    name: 'FAQ',
    linkTo: '/faq',
    className: 'nav-link',
  },
  {
    name: 'Academy',
    linkTo: 'https://hexospark.com/academy/',
    className: 'nav-link',
    subMenu: [
      {
        name: 'Email Templates',
        linkTo: 'https://hexospark.com/academy/the-best-cold-email-outreach-templates-library/',
        className: 'nav-link',
      },
      {
        name: 'Insider Guides',
        linkTo: 'https://hexospark.com/academy/category/insider-guides/',
        className: 'nav-link',
      },
      {
        name: 'News',
        linkTo: 'https://hexospark.com/academy/category/news/',
        className: 'nav-link',
      },
      {
        name: 'Tutorials',
        linkTo: 'https://hexospark.com/academy/hexospark-tutorials/',
        className: 'nav-link',
      },
    ],
  },
];

export const VALID_PASSWORD_RE =
  /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;
