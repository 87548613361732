import React, {useContext, useEffect, useState} from 'react';
import {useMutation} from '@apollo/client';
import {navigate} from 'gatsby';
import Payment from '../../../components/pricing/payment/payment-new';
import SimpleLayout from '../../../components/layout_simple';
import {UserContext} from '../../../contexts/user-context';
import {RESEND_VERIFICATION_EMAIL} from '../../../graphql/mutations';
import {removePackageInfo} from '../../../helpers';
import './styles.scss';

const Checkout = () => {
  const {user, hexosparkUser} = useContext(UserContext);
  const [selected, setSelected] = useState<any>(null);
  const [showPaypalMessage, setShowPaypalMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);

  const [receiveEmail, {loading}] = useMutation(RESEND_VERIFICATION_EMAIL, {
    onCompleted: (data: any) => handleConfirm(data),
  });

  useEffect(() => {
    if (localStorage.getItem('checkout-data')) setSelected(JSON.parse(localStorage.getItem('checkout-data') || ''));
    else if (!success) navigate('/pricing');
  }, []);

  useEffect(() => {
    // if (
    //   user?.promoData?.hexowatch_ltd &&
    //   ((selected?.name === 'Solo' &&
    //     (hexosparkUser?.hsUser?.pricing_package === 'TEAM' || hexosparkUser?.hsUser?.pricing_package === 'SOLO')) ||
    //     hexosparkUser?.hsUser?.pricing_package === 'PAID') && !success
    // ) {
    //   navigate('/pricing');
    //   removePackageInfo();
    // } else if (
    //   ((hexosparkUser?.hsUser?.paypal_subscription_id && hexosparkUser?.hsUser?.pricing_package === 'TEAM') ||
    //     hexosparkUser?.hsUser?.pricing_package_interval === 'LTD' ||
    //     (hexosparkUser?.hsUser?.pricing_package !== 'FREE' &&
    //       hexosparkUser?.hsUser?.pricing_package &&
    //       selected?.name === 'Solo')) &&
    //   !success &&
    //   !user?.promoData?.hexowatch_ltd
    // ) {
    //   navigate('/pricing');
    //   removePackageInfo();
    // }
    if (hexosparkUser?.hsUser?.pricing_package_interval !== 'LTD' && hexosparkUser?.hsUser?.pricing_package !== 'FREE' && selected?.interval === 'LTD') {
      navigate('/pricing');
    }
  }, [hexosparkUser, selected, success]);

  const handleConfirm = async (data: any): Promise<void> => {
    if (data.UserOps.resendVerification && !data.UserOps.resendVerification.error) {
      setMessage('success');
    } else if (
      data.UserOps.resendVerification.error &&
      data.UserOps.resendVerification.message === 'Please try few minutes later.'
    ) {
      setMessage(data.UserOps.resendVerification.message);
    } else {
      setMessage('error');
    }
  };

  return (
    <>
      {user?.id && !user.verified ? (
        <SimpleLayout isClickable>
          {loading || !user?.id ? (
            <div className="w-100 d-flex">
              <div className="spinner-border spinner-color m-auto" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          ) : message !== 'error' ? (
            <p className="text-center p-2 verify-email-section">
              <div className="text-center max-width-420">
                <div className="text-center">
                  <p className="lets-verify">Now let’s verify your email</p>
                  Check <span className="email-blue">{user?.email}</span> to verify your account and get started.
                </div>
              </div>
              <p className="mt-2" style={{lineHeight: '25px'}}>
                <span className="click-here" onClick={() => receiveEmail()}>
                  Click here
                </span>{' '}
                to resend the confirmation email.
              </p>

              {message && (
                <p className={`text-${message === 'success' ? 'success' : 'danger'} center mt-3`}>
                  {message === 'success' ? 'Verification email successfully sent!' : message}
                </p>
              )}
            </p>
          ) : (
            <>{message === 'error' && <p className="text-danger text-center">Verification Failed!</p>}</>
          )}
        </SimpleLayout>
      ) : (
        <SimpleLayout noIllustration>
          <div className="checkout-page">
            {loading || !user?.id ? (
              <div className="w-100 d-flex">
                <div className="spinner-border spinner-color m-auto" role="status">
                  <span className="sr-only"></span>
                </div>
              </div>
            ) : (
              <>
                {selected && (
                  <Payment
                    id={selected.id}
                    paypal_id={selected.ltd ? selected.id : selected.paypal_id}
                    count={selected.name === 'Solo' ? 1 : (selected.ltd ? selected.value : selected.count)}
                    inboxes={selected.name === 'Solo' ? 1 : selected.value}
                    showPaypalMessage={showPaypalMessage}
                    setShowPaypalMessage={setShowPaypalMessage}
                    price={selected.price}
                    isTrial={selected.isTrial}
                    name={selected.name}
                    setSuccess={setSuccess}
                    success={success}
                    annual={selected.annual}
                    ltd={selected.ltd}
                  />
                )}
              </>
            )}
          </div>
        </SimpleLayout>
      )}
    </>
  );
};

export default Checkout;
