import React, {FC, useContext, useEffect, useState} from 'react';
import {useMutation, useQuery} from '@apollo/client';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import axios from 'axios';
import {UserContext} from '../../../contexts/user-context';
import {ADD_CARD, UPDATE_PACKAGE} from '../../../graphql/mutations';
import {LIST_CUSTOMER_CARD} from '../../../graphql/queries';
import {cardsFirstNumbers} from '../../../helpers/constants';
import useSetUser from '../../../hooks/useSetUser';
import {closeIcon, paypalBtnIcon} from '../../../images/svgIcons';
import {CardStateData} from '../../types';
import CreditCardForm from '../creditCardForm';
import PaymentChooseCard from '../paymentChooseCard';
import PaymentFooter from '../paymentFooter';
import PaymentLoader from '../paymentLoader';
import PaymentSuccess from '../paymentSuccess';
import CustomModal from '../../customModal';
import Visa from '../../../images/payments/Visa.png';
import AmericanExpress from '../../../images/payments/AmericanExpress.png';
import MasterCard from '../../../images/payments/MasterCard.png';
import UnionPay from '../../../images/payments/UnionPay.png';
import Discover from '../../../images/payments/Discover.png';
import DinersClub from '../../../images/payments/DinersClub.png';
import JCB from '../../../images/payments/JCB.png';
import Messages from '../../messages';
import moment from 'moment';
import './styles.scss';
import ProductCard from '../productCard';

const Payment: FC<any> = ({
  id,
  paypal_id,
  count,
  showPaypalMessage,
  setShowPaypalMessage,
  price,
  isTrial,
  name,
  success,
  setSuccess,
  annual,
  inboxes,
  ltd,
}) => {
  const [getUser] = useSetUser();
  //-----------------Context-------------------
  const {user, hexosparkUser} = useContext(UserContext);
  //------------------States-------------------
  let secondWindow: any;
  let int: any;
  const [el, setEl] = useState(null as any);
  const [stripe, setStripe] = useState<any>(null);
  const [cardId, setCardId] = useState('');
  const [customLoading, setCustumLoading] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  const [editCardInfo, setEditCardInfo] = useState(2);
  const [err, setErr] = useState('');
  const [fieldError, setFieldError] = useState(false);
  const [paypalToken, setPaypalToken] = useState('');
  const [cardInfo, setCardInfo] = useState({
    name: '',
    number: '',
    expiry: '',
    cvc: '',
    focus: '',
  });
  const [defaultCardInfo, setDefaultCardInfo] = useState({
    name: '',
    number: '',
    expiry: '',
    cvc: '',
    focus: '',
  });
  const [paidWithPaypal, setPaidWithPaypal] = useState(false);
  //-----------------Queries----------------------------
  const {
    data: creditCards,
    loading: cardsLoading,
    refetch: refetchCardList,
  } = useQuery(LIST_CUSTOMER_CARD, {
    fetchPolicy: 'no-cache',
    variables: {
      settings: {
        limit: 100,
      },
    },
  });
  const [addPaymentMethod, {loading}] = useMutation(ADD_CARD);
  // const [paypalApproved] = useMutation(PAYPAL_PAYMENT_APPROVED);

  const [createSubscription, {error: subscribtionError, loading: subscriptionLoading}] = useMutation(UPDATE_PACKAGE);

  //-------------------useEffects--------------------------
  useEffect(() => {
    //@ts-ignore
    window['Stripe'].setPublishableKey(
      window.location.hostname.includes('hexospark.com')
        ? 'pk_live_8KwSrH69LnrJea7rrCH7Phn1'
        : 'pk_test_awe3SQdhRsX7VjRATH8uPbyW',
    );
    setStripe(
      //@ts-ignore
      window['Stripe'](
        window.location.hostname.includes('hexospark.com')
          ? 'pk_live_8KwSrH69LnrJea7rrCH7Phn1'
          : 'pk_test_awe3SQdhRsX7VjRATH8uPbyW',
      ),
    );
  }, []);

  useEffect(() => {
    if (err) {
      setCustumLoading(false);
      setTimeout(() => setErr(''), 10000);
    }
  }, [err]);

  useEffect(() => {
    if (
      creditCards &&
      creditCards.Billing &&
      creditCards.Billing.listCustomerCards &&
      creditCards.Billing.listCustomerCards.cards
    ) {
      const defaultCard =
        creditCards.Billing.listCustomerCards.cards.length === 1
          ? creditCards.Billing.listCustomerCards.cards[0]
          : creditCards.Billing.listCustomerCards.cards.find((item: any) => item.isDefault === 'true');
      if (defaultCard) {
        setCardId(defaultCard.id);
        setDefaultCardInfo({
          cvc: '***',
          expiry: '**/**',
          focus: defaultCard.brand,
          name: '**** ****',
          number:
            (cardsFirstNumbers[defaultCard.brand.toUpperCase()]
              ? cardsFirstNumbers[defaultCard.brand.toUpperCase()]
              : '****') +
            '********' +
            defaultCard.lastDigits,
        });
        editCardInfo !== 3 && setEditCardInfo(1);
      }
    }
  }, [creditCards]);

  useEffect(() => {
    setErr('');
  }, [editCardInfo]);

  useEffect(() => {
    if (success) getUser();
  }, [success]);

  useEffect(() => {
    if (subscribtionError) {
      setCustumLoading(false);
      setErr('Payment failed.');
      setTimeout(() => setErr(''), 5000);
    } else setErr('');
  }, [subscribtionError]);

  //functions
  const analytics = () => {
    if (window.location.href.includes('hexospark.com')) {
      //@ts-ignore
      console.log(window.dataLayer);
      //@ts-ignore
      window.dataLayer.push({
        event: 'purchase-button-click',
        purchaseOrderId: new Date().toISOString(),
        purchaseConversionValue: `${(price || 0) * (count || 1)}`,
      });
      //@ts-ignore
      window.dataLayer.push({
        event: 'eec.purchase',
        ecommerce: {
          currencyCode: 'USD',
          purchase: {
            actionField: {
              id: `PAID_${new Date().toISOString()}`,
              revenue: `${(price || 0) * (count || 1)}`,
            },
            products: [
              {
                item_id: `PAID_${new Date().toISOString()}`,
                item_name: `PAID_LTD`,
                price: `${price || 0}`,
                quantity: count || 1,
                startTrial: isTrial,
              },
            ],
          },
        },
      });
    }
  };

  useEffect(() => {
    if (!showPaypalMessage && localStorage.getItem('payment_success')) {
      //paypalApproved({variables: {settings: {productType: 'hexospark'}}});
      setSuccess(true);
      setPaidWithPaypal(true);
      localStorage.removeItem('payment_success');
      analytics();
    }
  }, [showPaypalMessage]);

  //-------------------Functions---------------------------

  const handleUpgrade = async (card: string) => {
    setCustumLoading(true);
    setSuccess(false);
    setPaidWithPaypal(false);
    setErr('');
    setFieldError(false);
    const res = await createSubscription({
      variables: {
        settings: {
          productId: id,
          cardId: card,
          paymentType: 'STRIPE',
          packageCount: count,
          startTrial: isTrial,
        },
      },
    });
    if (
      res &&
      res.data &&
      res.data.HexosparkUserOps &&
      res.data.HexosparkUserOps.updateHexosparkPackage &&
      res.data.HexosparkUserOps.updateHexosparkPackage.clientSecret
    ) {
      //@ts-ignore
      const {paymentIntent, error} = await stripe.confirmCardPayment(
        res.data.HexosparkUserOps.updateHexosparkPackage.clientSecret,
        {
          payment_method: res.data.HexosparkUserOps.updateHexosparkPackage.card,
        },
      );
      if (paymentIntent && paymentIntent.status === 'succeeded') {
        setSuccess(true);
        setPaidWithPaypal(false);
        analytics();
      } else setErr('Payment failed.');
      setCustumLoading(false);
      return;
    }
    if (
      res &&
      res.data &&
      res.data.HexosparkUserOps &&
      res.data.HexosparkUserOps.updateHexosparkPackage &&
      res.data.HexosparkUserOps.updateHexosparkPackage.error_code
    ) {
      setErr('Payment failed.');
    } else {
      setSuccess(true);
      setPaidWithPaypal(false);
      analytics();
    }

    setCustumLoading(false);
  };
  const handlePayment = async (cardData: CardStateData) => {
    const expiry = cardData.expiry.split('/');
    try {
      //@ts-ignore
      window.Stripe.card.createToken(
        {
          number: cardData.number,
          exp_month: +expiry[0],
          exp_year: +expiry[1].slice(0, 2),
          cvc: cardData.cvc,
        },
        //@ts-ignore
        (status, response) => {
          if (status === 200) {
            if (response && response.id) addCard(cardData, response.id);
            else setErr('Invalid parameters.');
          } else setErr('Invalid parameters.');
        },
      );
    } catch {
      setErr('Something went wrong');
    }
  };
  const addCard = async (cardData: CardStateData, id: string) => {
    try {
      const res = await addPaymentMethod({
        variables: {
          settings: {
            email: user && user.email,
            name: cardData.name,
            source: id,
          },
        },
      });

      const errors =
        res && res.data && res.data.BillingOps && res.data.BillingOps.addCard
          ? res.data.BillingOps.addCard.error_code
          : null;
      if (errors) setErr(errors === 'Try later' ? 'Try later' : 'Invalid card.');
      if (res && res.data && res.data.BillingOps && res.data.BillingOps.addCard && res.data.BillingOps.addCard.cardId) {
        await handleUpgrade(res.data.BillingOps.addCard.cardId);
      }
      refetchCardList();
      setCustumLoading(false);
    } catch {
      setErr('Something went wrong');
    }
  };

  const handlePaymentFormSubmit = async () => {
    setErr('');
    const obj = {...cardInfo, focus: 'name'};
    if (Object.values(obj).filter(item => !item).length > 0) {
      setFieldError(true);
      return;
    }
    setFieldError(false);
    setCustumLoading(true);
    handlePayment(obj);
  };
  const upgradeByPaypal = async () => {
    const y = (window.outerHeight - 470) / 2;
    const x = (window.outerWidth - 400) / 2;
    secondWindow = window.open('', 'secondary', `width=400,height=470,top=${y},left=${x}`);
    try {
      const {data} = await createSubscription({
        variables: {
          settings: {
            productId: paypal_id,
            paymentType: 'PAYPAL',
            returnUrl: window.location.href,
            packageCount: count,
            startTrial: isTrial,
          },
        },
      });

      if (
        data &&
        data.HexosparkUserOps &&
        data.HexosparkUserOps.updateHexosparkPackage &&
        data.HexosparkUserOps.updateHexosparkPackage.approveUrl
      ) {
        secondWindow && (secondWindow.location = data.HexosparkUserOps.updateHexosparkPackage.approveUrl);
        setEl(secondWindow);
        setShowPaypalMessage && setShowPaypalMessage(true);
        const token = data.HexosparkUserOps.updateHexosparkPackage.approveUrl;
        token && setPaypalToken(token.slice(token.indexOf('token=') + 6));
        int = setInterval(() => {
          if (!secondWindow || secondWindow.closed) {
            clearInterval(int);
            setShowPaypalMessage && setShowPaypalMessage(false);
            setEl(null);
          }
        });
      } else {
        secondWindow?.close();
      }
    } catch {
      secondWindow?.close();
      setErr('Something went wrong');
    }
  };

  const getSuccessMessage = async () => {
    const data = await axios('https://api.hexospark.com/paypal/check', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        token: !paypalToken.includes('BA-') ? paypalToken : undefined,
        ba_token: paypalToken.includes('BA-') ? paypalToken : undefined,
      },
    });
    if (data?.data?.success) {
      setSuccess(true);
      setPaidWithPaypal(true);
      // localStorage.removeItem('payment_success');
      analytics();
    }
  };

  useEffect(() => {
    if (!showPaypalMessage && paypalToken) {
      getSuccessMessage();
    }
  }, [showPaypalMessage, paypalToken]);

  const handleConfirm = () => {
    editCardInfo === 3
      ? upgradeByPaypal()
      : defaultCardInfo.name && editCardInfo === 1
      ? handleUpgrade(cardId)
      : handlePaymentFormSubmit();
  };

  const handleShowSecondWindow = () => el && el.focus();
  const handleCloseSecondWindow = () => el && el.close();

  useEffect(() => {
    const paypal = hexosparkUser?.hsUser?.paypal_subscription_id;
    const stripe = hexosparkUser?.hsUser?.stripe_subscription_id;
    if (paypal && !stripe) {
      setEditCardInfo(3);
    }
  }, [hexosparkUser]);
  const handleShowDetails = () => setShowDetails(true);
  return (
    <>
      <div className="payment">
        {!customLoading && !loading ? (
          !success ? (
            <>
              {cardsLoading ? (
                <div className="d-flex align-items-center mt-5 pt-5 justify-content-center">
                  <div className="spinner-border spinner-color m-auto" role="status">
                    <span className="sr-only"></span>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="name">
                    {name}
                    {isTrial ? ', 7-day free' : ''}
                  </div>
                  <div className="d-block d-lg-flex cards-wrapper px-3">
                    <div className="max-width-550 payment-card">
                      <div className="payment-details mt-0">Payment method</div>
                      {!cardsLoading && (
                        <PaymentChooseCard
                          onChange={setEditCardInfo}
                          editCardInfo={editCardInfo}
                          defaultCard={!!(defaultCardInfo && defaultCardInfo.name)}
                          paypal={!hexosparkUser?.hsUser?.stripe_subscription_id}
                          stripe={!hexosparkUser?.hsUser?.paypal_subscription_id}
                        />
                      )}
                      <div
                        className={`payment-icons text-center d-flex align-items-center justify-content-center mt-3 ${
                          editCardInfo === 3 ? 'invisible' : ''
                        }`}
                      >
                        <img src={Visa} alt="Visa" className="m-1" />
                        <img src={MasterCard} alt="Master Card" className="m-1" />
                        <img src={AmericanExpress} alt="American Express" className="m-1" />
                        <img src={Discover} alt="Discover" className="m-1" />
                        <img src={JCB} alt="JCB" className="m-1" />
                        <img src={DinersClub} alt="Diners Club" className="m-1" />
                        <img src={UnionPay} alt="Union Pay" className="m-1" />
                      </div>
                      {!cardsLoading && (
                        <>
                          {editCardInfo !== 3 ? (
                            <>
                              <div className="payment-details">Payment details</div>
                              <CreditCardForm
                                cardInfo={defaultCardInfo.name && editCardInfo === 1 ? defaultCardInfo : cardInfo}
                                loading={loading || cardsLoading}
                                error={err}
                                changeData={setCardInfo}
                                disabled={!defaultCardInfo.name ? false : editCardInfo === 1}
                                fieldError={fieldError}
                                hideCard
                              />
                            </>
                          ) : (
                            <div className="w-75 text-center mx-auto my-4 pay_with_paypal">
                              Click <strong>Pay with PayPal</strong> to log into your account and confirm your purchase.
                              You'll be redirected to this page to finish up.
                            </div>
                          )}
                        </>
                      )}
                    </div>
                    {!cardsLoading && !success && !customLoading && (
                      <div className="payment-card price-info mx-auto">
                        <div className="payment-details mt-0">Review your order</div>
                        <div className="package-info">
                          <div className="d-flex justify-content-between align-items-start">
                            <div className="main">{name}</div>
                            <div>
                              <div className="main">${price * (count || 1)}</div>
                              {ltd ? <div>lifetime deal</div> : <div>per {annual ? 'year' : 'month'}</div>}
                            </div>
                          </div>
                          <div>
                            {inboxes} Email inbox{inboxes > 1 ? 's' : ''} <br />
                            Unlimited contacts & emails
                          </div>
                          <div className="plan-details" onClick={handleShowDetails}>
                            Plan details
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-start mb-3">
                          {isTrial ? (
                            <div className="w-100 free-trial">
                              <div className="d-flex align-items-center justify-content-between one">
                                <div>Due {moment().add(7, 'days').utc().format('ll')}</div>
                                <div>
                                  <div className="main">${price * (count || 1)}</div>
                                  <div>per month</div>
                                </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-between">
                                <div className="due-today">Due today {isTrial && <span>(7-day free)</span>}</div>
                                <div className="due-price">${isTrial ? '0' : price * (count || 1)}</div>
                              </div>
                            </div>
                          ) : (
                            <>
                              <div className="total">Total</div>
                              <div>
                                <div className="total">${price * (count || 1)}</div>
                                {ltd ? <div>lifetime deal</div> : <div>per {annual ? 'year' : 'month'}</div>}
                              </div>
                            </>
                          )}
                        </div>
                        <p className={`w-100 pl-3 text-center payment-error ${err ? '' : 'd-none'}`}>
                          <Messages type={'error'} text={err} />
                        </p>
                        <PaymentFooter
                          confirm={handleConfirm}
                          paypal={editCardInfo === 3}
                          loading={subscriptionLoading}
                        />
                        {ltd ? null : (
                          <div className="info-block">
                            <div>
                              Your plan will be renewed {isTrial || annual ? 'on ' : ''}
                              <span>
                                {isTrial
                                  ? moment().add(7, 'days').utc().format('ll')
                                  : annual
                                  ? moment().add(360, 'days').utc().format('ll')
                                  : 'after a month'}
                              </span>
                              .{' '}
                            </div>
                            <div>
                              You can cancel or modify your subscription at any time in the "My plans" section of your
                              dashboard. When you cancel, your plan is kept active until the next billing date.
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          ) : (
            <PaymentSuccess
              onClick={(e: any) => {
                window.location.href = 'https://dash.hexospark.com';
              }}
              message={
                paidWithPaypal
                  ? 'Thank you for your order, your payment is being processed (can take a few minutes to show in your account)'
                  : ''
              }
            />
          )
        ) : (
          <PaymentLoader />
        )}
      </div>
      {showPaypalMessage && (
        <CustomModal
          setShowPopup={() => {}}
          noCloseIcon
          className={`payment-modal ${showPaypalMessage ? 'no-background' : ''}`}
        >
          <div className="show-paypal-info">
            <span onClick={handleCloseSecondWindow} className="close-icon">
              {closeIcon}
            </span>
            <div className="paypal-wrapper">
              <div className="pb-2">{paypalBtnIcon}</div>
              Don’t see the secure PayPal browser? We’ll help you re-launch the window to complete your purchase
              <div className="cursor-pointer pt-2" onClick={handleShowSecondWindow}>
                <strong>Click to Continue</strong>
              </div>
              {/* <div onClick={handleCloseSecondWindow} className="cursor-pointer mt-2 close-info">
                <>Close</>
              </div> */}
            </div>
          </div>
        </CustomModal>
      )}
      {showDetails && (
        <CustomModal setShowPopup={setShowDetails} className="package-info-modal" noNavigate>
          <ProductCard
            id={id}
            paypal_id={paypal_id}
            price={price}
            verified={!!user?.verified}
            annual={annual}
            name={name}
            count={count}
            selectDisabled
            ltd={ltd}
            planDetail
          />
        </CustomModal>
      )}
    </>
  );
};

export default Payment;
