import React, {FC, memo, ReactElement, useCallback, useEffect, useState} from 'react';
import useClickOutside from '../../../hooks/useClickOutside';
import {arrowDownIcon} from '../../../images/svgIcons';
import Input from '../input';
import './styles.scss';

export interface SelectTypes {
  value?: string | number | any | {label?: string; value: string};
  options: any;
  children?: any;
  onChange: (arg0: string | any) => void;
  width?: any;
  disabled?: boolean;
  manySelect?: boolean;
  className?: string;
  placeHolder?: string;
  bottom?: boolean;
  icon?: ReactElement;
  showLeft?: boolean;
  showRight?: boolean;
  empty?: boolean;
  searchable?: boolean;
}

const Select: FC<SelectTypes> = ({
  value,
  options,
  onChange,
  children,
  disabled,
  manySelect,
  className,
  placeHolder,
  icon,
  showLeft,
  showRight,
  empty,
  searchable,
}) => {
  const [showOptions, selectRef, setShowOptions] = useClickOutside();
  const [showInput, setShowInput] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [newOptions, setNewOptions] = useState(options);
  const [filteredOptions, setFilteredOptions] = useState(options);

  useEffect(() => {
    const new_option = !searchable ? options : filteredOptions;
    setNewOptions(new_option);
  }, [options, filteredOptions, searchable, value]);

  const handleChangeInput = useCallback(
    (e: any) => {
      setSearchText(e.target.value);
      const newOpt = [...options];
      const fil = newOpt.filter((op: {label: string}) =>
        op.label.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()),
      );
      setFilteredOptions(e.target.value && e.target.value.trim() ? fil : options);
      onChange(e.target.value);
    },
    [options, onChange],
  );

  const handleHideInput = useCallback(() => {
    searchText && setSearchText('');
    showInput && setShowInput(false);
  }, [showInput]);

  const handleShowInput = useCallback(() => {
    !showInput && setShowInput(true);
  }, [showInput]);

  const handleIconClick = useCallback(() => {
    !disabled && setShowOptions(!showOptions);
  }, [disabled, showOptions]);

  const getOption = useCallback(
    (item: any) => {
      const onClick = () => {
        onChange(item);
        !manySelect && setShowOptions(false);
      };
      if (typeof item === 'string' || typeof item === 'number') {
        item = {
          label: item,
          value: item,
        };
      }
      return (
        <p
          className={`option ${item.value} ${
            value === item.label ||
            (manySelect && value && value.find((v: any) => v === item.value)) ||
            (value === 'all' && item.value === '') ||
            (typeof value !== 'string' && typeof value !== 'number' && value && value.value === item.value)
              ? !manySelect
                ? ''
                : 'selected'
              : ''
          } ${value.value === item.value ? 'active' : ''}`}
          key={item.label}
          onClick={onClick}
        >
          {item.label}
        </p>
      );
    },
    [value, manySelect, onChange],
  );
  return (
    <div
      className={`custom-universal-select ${className ? className : ''} ${
        showLeft ? 'show-left' : showRight ? 'show-right' : ''
      }`}
      ref={selectRef}
    >
      {showLeft || showRight ? (
        <span onClick={handleIconClick}>{icon}</span>
      ) : (
        <button
          className={`select ${showOptions ? 'border-color' : ''} ${empty ? 'border-empty' : ''}`}
          disabled={disabled}
          onClick={handleIconClick}
        >
          {searchable && (
            <div className={`${showInput ? 'show' : 'hide'}`}>
              <Input
                value={searchText}
                onChange={handleChangeInput}
                className="no-border-input"
                placeholder={typeof value === 'object' ? value?.label : value || ''}
                onFocus={handleShowInput}
                onBlur={handleHideInput}
                onKeyDown={handleShowInput}
                type="text"
                name="text"
              />
            </div>
          )}
          {searchable && placeHolder && !value && !showInput && <span style={{color: '#9ca3b9'}}>{placeHolder}</span>}
          {!searchable && placeHolder && !value ? (
            <span>{placeHolder}</span>
          ) : (
            <>
              {!showInput && (
                <span
                  className={`selected-value noselect ${
                    value.label === 'Select' || value.label === 'Please select one' ? 'text-secondary' : ''
                  }`}
                >
                  {typeof value === 'string' || typeof value === 'number' ? value : value && value.label}
                </span>
              )}
              {icon ? icon : <span className="arrow-icon">{arrowDownIcon}</span>}
            </>
          )}
        </button>
      )}
      {showOptions && (
        <div className="options">
          {children ? children : newOptions && newOptions.length ? newOptions.map((item: any) => <p key={item.value}>{getOption(item)}</p>) : <div className='text-center'>No result</div>}
        </div>
      )}
    </div>
  );
};

export default memo(Select);
